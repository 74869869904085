import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { createGlobalStyle } from "styled-components"
import gql from "graphql-tag"
import SEO from "../components/seo"
import { Link } from "gatsby"
import style from "../css/hero.module.css"

import Layout from "../components/layout"

const Fonts = createGlobalStyle`
    @font-face {
      font-family: "Avenir";
      src: url("../fonts/medium.otf");
    }
`

const Container = styled.div`
  background-color: #121212;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
`

const Content = styled.div`
  background-color: #121212;
  display: flex;
  width: 400px;
  margin-top: 50px;
  margin-left: 20px;
  flex-direction: column;
  min-height: 100vh;
`

const Section = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const Title = styled.p`
  font-size: 35px;
  font-family: "Avenir";
  font-weight: 700;
  color: #fff;
  align-self: left;
  margin-bottom: 20px;
`

const BigTitle = styled.p`
  font-size: 40px;
  text-align: center;
  font-family: "Avenir";
  font-weight: 700;
  color: #fdde68;
  align-self: center;
  margin-bottom: 40px;
  margin-top: 20px;
`

const SubTitle = styled.p`
  font-size: 30px;
  font-family: "Avenir";
  font-weight: 400;
  color: #fff;
  text-align: left;
  margin-bottom: 30px;
`

const DownloadBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 240px;
  height: 58px;
  background-color: #fdde68;
  border-radius: 25px;
  font-size: 23px;
  font-family: "Avenir";
  font-weight: 700;
  color: #333;
  margin-bottom: 20px;
`

const BigDownloadBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 300px;
  height: 75px;
  background-color: #fdde68;
  border-radius: 25px;
  font-size: 20px;
  font-family: "Avenir";
  font-weight: 700;
  color: #333;
  margin-top: 25px;
`

const SmallText = styled.p`
  font-size: 20px;
  font-family: "Avenir";
  font-weight: 400;
  color: #fff;
  text-align: left;
  margin-bottom: 30px;
`

//Intro
const IntroSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
`

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
`

const YellowLongArrow = styled.div`
  align-items: center;
`

const WhiteText = styled.p`
  font-family: "Avenir" !important;
  font-size: 1.3rem !important;
  line-height: 1.3;
  color: #fff !important;
  max-width: 95% !important;
  align-self: center;

  margin-bottom: 3rem;
`

const IntroDownload = styled(props => <a {...props} />)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 20px;
  width: 240px;
  height: 58px;
  background-color: #fdde68;
  border-radius: 25px;
  font-size: 23px;
  font-family: "Avenir";
  font-weight: 700;
  color: #333;
  margin-bottom: 40px;
  outline: none !important;
  box-shadow: none !important;
  border: none;
  text-decoration: none;
`

const StyledLink = styled(props => <Link {...props} />)`
  font-family: "Avenir";
  font-size: 1.3rem;
  text-decoration: underline;
  margin-top: 50px;
  color: #fdde68;

  @media (min-width: 650px) {
    font-size: 1.4rem;
  }
`

export default function QueueFriday(props) {
  const handleClick = () => {
    if (window.analytics) {
      window.analytics.track("Clicked #QueueFriday follow")
    }
    window.location.href = "https://instagram.com/join_vibe"
  }

  return (
    <Layout>
      <SEO title="#QueueFriday" />
      <Container>
        <Content>
          <TextSection>
            <BigTitle>
              <p style={{ marginBottom: 0, marginTop: 0 }}>#QueueFriday</p>
            </BigTitle>
          </TextSection>
          <TextSection>
            <WhiteText>
              <p style={{ marginBottom: 0, marginTop: 0 }}>
                Vind 1000 kr. på fredag
              </p>
            </WhiteText>
          </TextSection>

          <IntroSection>
            <YellowLongArrow>
              <img
                style={{
                  resizeMode: "contain",
                  width: 20,
                  height: 100,
                }}
                src={require("../images/yellowarrow.png")}
              />
            </YellowLongArrow>
          </IntroSection>
          <IntroSection>
            <IntroDownload onClick={() => handleClick()}>
              <p style={{ marginBottom: 0, fontSize: 30 }}>Følg nu👉</p>
            </IntroDownload>
          </IntroSection>
          <TextSection>
            <StyledLink to="/legal">Legal</StyledLink>
          </TextSection>
        </Content>
      </Container>
    </Layout>
  )
}
